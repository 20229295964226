import '@/sass/overrides.sass'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import DayJsAdapter from '@date-io/dayjs'
import { de, it, en } from 'vuetify/locale'
import i18n from '@/i18n'

const theme = {
  primary: '#FF7A59',
  secondary: '#FF7A59',
  accent: '#9C27b0',
  info: '#00CAE3',
  indigo: '#7f7f7f',
  error: '#db3236',
  background: '#eeeeee'
}

const VuetifyObj = createVuetify({
  theme: {
    themes: {
      dark: { colors: theme },
      light: { colors: theme },
    },
  },
  components, directives,
  icons: {
    defaultSet: 'mdi'
  },
  date: {
    adapter: DayJsAdapter
  },
  locale: {
    locale: i18n.global.locale,
    fallback: 'de',
    messages: { de, en, it }
  }
})

export default VuetifyObj
