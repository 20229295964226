import axios from 'axios'

const companyStore = {
  state: {
    company: null,
    coworkers: null,
  },
  getters: {
    company: (state: any) => state.company,
    coworkers: (state: any) => state.coworkers,
  },
  mutations: {
    setCompany (state: any, payload: any) {
      state.company = payload
    },
    setCoworkers (state: any, payload: any) {
      state.coworkers = payload
    },
    resetCompany (state: any) {
      state.company = null
      state.coworkers = null
    },
  },
  actions: {
    async fetchCompany ({ commit }: any, id: string | null = null) {
      try {
        if (id) {
          axios.get(`/api/company/${id}`).then((result: any) => {
            commit('setCompany', result.data)
          })
        } else {
          axios
            .get('/api/company')
            .then((result: any) => {
              if (result.data) {
                commit('setCompany', result.data)
              }
            })
            .catch(() => {
              console.log('user is not in a company')
              commit('setCompany', null)
              // no company found
            })
        }
      } catch (err) {
        console.error(err)
        commit('setCompany', null)
      }
    },
  },
}

export default companyStore
