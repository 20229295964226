export const en = {
  "save": "Save",
  "update": "Update",
  "accept": "Accept",
  "cancel": "Cancel",
  "close": "Close",
  "add": "Add",
  "check": "Check",
  "buttons": "Buttons",
  "calendar": "Calendar",
  "components": "Components",
  "certificate": "Certificate",
  "certificates": "Certificates",
  "certification": "Certification",
  "certifications": "Certifications",
  "valid-certificate": "Valid certificate",
  "date": "Date",
  "valid-thru": "Valid thru",
  "dashboard": "Dashboard",
  "dtables": "Data Tables",
  "eforms": "Extended Forms",
  "error": "Error Page",
  "forms": "Forms",
  "maps": "Maps",
  "notifications": "Notifications",
  "pages": "Pages",
  "plan": "Choose Plan",
  "pricing": "Pricing",
  "my-profile": "My profile",
  "register-headline": "Register",
  "register": "Register",
  "search": "Search",
  "settings": "Settings",
  "tables": "Tables",
  "tabs": "Tabs",
  "myeval": "EvalCard",
  "eval-id": "Eval-ID",
  "id": "ID",
  "timeline": "Timeline",
  "typography": "Typography",
  "admin": "Administrator",
  "user": "User",
  "users": "Users",
  "username": "Username",
  "username-or-email": "Username or Email",
  "user-profile": "User Profile",
  "company-name": "Companyname",
  "company-logo": "Companylogo",
  "company-profile": "Companyprofile",
  "company-profile-admin": "Companyprofile",
  "company-invitation": "Companyinvitation",
  "invitation-sent": "Invitation sent",
  "new-password": "New password",
  "generate-new-password": "Generate new password",
  "current-password": "Current password",
  "repeat-password": "Repeat password",
  "password-successfully-changed": "Password successfully changed",
  "password-reset": "Password reset",
  "edit-user-profile": "Edit user profile",
  "edit-user-profile-admin": "Edit user profile",
  "system": "System",
  "vforms": "Validation Forms",
  "widgets": "Widgets",
  "wizard": "Wizard",
  "logout": "Logout",
  "login": "Login",
  "companies": "Companies",
  "my-company": "My company",
  "leave-company": "Leave company",
  "delete-company": "Delete company",
  "company": "Company",
  "uid": "UID",
  "employees": "Employees",
  "employee": "Employee",
  "companyadmin": "Admin",
  "evalclient": "Evalclient",
  "coworker": "Employees",
  "coworker-profile": "Employee's Profile",
  "trainer": "Trainer",
  "authorityAdmin": "Admin",
  "trainerRO": "Trainer (Readonly)",
  "authorityAdminRO": "Admin (Readonly)",
  "protocols": "Test Reports",
  "all-protocols": "All Test Reports",
  "report": "Test Report",
  "new-report": "New report",
  "reports": "Test Reports",
  "all-reports": "All Test Reports",
  "report-from": "Report from",
  "report-details": "Report details",
  "data": "Data",
  "info": "Info",
  "attachment": "Attachment",
  "contact": "Contact",
  "continue": "Continue",
  "summary": "Summary",
  "vin": "VIN",
  "licence-plate": "Licence plate",
  "owner": "Owner",
  "vehicles": "Vehicles",
  "vehicle": "Vehicle",
  "vehicle-details": "Vehicle details",
  "companyVehicle": "Vehicle",
  "valid": "Valid",
  "invalid": "Invalid",
  "tester": "Tester",
  "vehicles-admin": "Vehicles",
  "create-company-vehicle": "Create vehicle",
  "select-vehicle": "Select vehicle",
  "my-vehicles": "My vehicles",
  "my-vehicle": "My vehicle",
  "new-vehicle": "New vehicle",
  "manufacturer": "Manufacturer",
  "no-manufacturer": "(No Manufacturer)",
  "no-vehicle": "(No Vehicle)",
  "vehicle-manufacturer": "Vehicle manufacturer",
  "create-vehicle": "Create vehicle",
  "create-vehicle-manufacturer": "Create vehicle manufacturer",
  "construction-year": "Constructionyear",
  "change-password": "Change password",
  "roles": "Roles",
  "actions": "Actions",
  "action": "Action",
  "profile": "Profile",
  "email": "Email",
  "basic": "Basic",
  "rights": "Rights",
  "details": "Details",
  "information": "Information",
  "name": "Name",
  "namename": "Description",
  "firstname": "Firstname",
  "lastname": "Lastname",
  "fullname": "Full name",
  "password": "Password",
  "street": "Street",
  "number": "Number",
  "city": "City",
  "country": "Country",
  "forgot-password": "Forgot password",
  "recover-password": "Recover password",
  "back": "Back",
  "back-to-login": "Back to login",
  "go-to-login": "Go to login",
  "choose-picture": "Choose picture",
  "choose-logo": "Choose logo",
  "send-invitation": "Send invitation",
  "zipcode": "Zipcode",
  "previous": "Previous",
  "abort": "Abort",
  "i-understand": "understand",
  "YES": "Yes",
  "no": "No",
  "yes": "Yes",
  "inputs": "Inputs",
  "user-activated": "User activated",
  "user-not-activated": "user not activated",
  "user-did-not-receive-activation-email": "user did not receive activation email yet",
  "allow-coworkers-to-show-on-profile": "Allow employees to show on profile:",
  "resend-activation-email": "Resend activation email",
  "coworker-education-status": "Employee qualification status",
  "card": "Card",
  "certification-authority": "Certification authority",
  "certification-authority-admin": "Certification authority",
  "certification-authorities": "Certification authorities",
  "delete-certification-authority": "Delete certification authority",
  "certificate-templates": "Certificate templates",
  "certificate-template": "Certificate template",
  "creating-certificates": "Creating certificates...",
  "issue-certificate": "Issue certificate",
  "issue-date": "Issue date",
  "due-date": "Due date",
  "issued-on": "Issued on",
  "issued-by": "Issued by",
  "author": "Author",
  "certificate-name": "Certificate name",
  "mileage": "Mileage",
  "certificate-number": "Certificate number",
  "born-on": "born on",
  "public": "Public",
  "back-to-the-app": "Back to the app",
  "can-create-reports": "Create reports possible",
  "valid-for": "Valid for(Years)",
  "pdf-template": "PDF-template",
  "pdf-certificate": "PDF-certificate",
  "show-pdf-certificate": "Show pdf-certificate",
  "standards": "Standards (HTML)",
  "text-block": "Text after Standards (HTML)",
  "digital-signature": "Digital signature",
  "eval1": "EVAL-1",
  "eval2": "EVAL-2",
  "eval3": "EVAL-3",
  "others": "Others",
  "level": "Level",
  "type": "Type",
  "training-data": "Training documents",
  "training-info": "Training information",
  "generated-password": "Generated password",
  "invitation-email": "Invitation Email",
  "document": "Document",
  "documents": "Documents",
  "active": "active",
  "inactive": "inactive",
  "all": "ALL",
  "all-sort": "All/Sort",
  "created-on": "Created on",
  "result": "Result",
  "results": "Results",
  "try-again": "Try again",
  "status": "Status",
  "sn": "SN",
  "something-went-wrong": "Something went wrong",
  "info-signs": "Signs",
  "danger": "Danger",
  "hazardous-voltage": "Hazardous Voltage",
  "no-access-1": "No Access",
  "no-access-2": "for unauthorized persons",
  "access-only-by": "Access only by",
  "company-logo-is-missing": "Companylogo is missing",
  "download": "Download",
  "access-denied": "Access denied",
  "dont-switch-on": "Don't switch on",
  "hv-system": "HV-System",
  "disconnected": "Disconnected",
  "disconnected-by": "Disconnected by",
  "works-in-progress": "Works in progress",
  "forum": "EV Forum",
  "title": "Title",
  "topic": "Topic",
  "topics": "Topics",
  "from": "from",
  "answers": "Answers",
  "post-answer": "Post answer",
  "edit-answer": "Edit answer",
  "created": "Created",
  "new-topic": "New topic",
  "edit-topic": "Edit topic",
  "create-new-topic": "Create new topic",
  "last-answer": "Last answer",
  "view-all": "View all",
  "attachments": "Attachments",
  "newest-topics": "Newest topics",
  "privacy-policy": "Privacy Policy",
  "new-files": "New Files",
  "existing-files": "Existing Files",
  "dialog": {
    "are-you-sure": "Are you sure?",
    "an-unexpected-error-occured": "An unexpected error occured. Try again later",
    "do-you-really-want-the-user-to": "Do you really want to delete the user",
    "do-you-really-want-the-invitation-to": "Wollen Sie wirklich die Einladung",
    "delete": "?",
    "delete-user": "Delete user",
    "delete-topic": "Delete topic",
    "delete-topic-answer": "Delete topic answer",
    "remove-user": "Remove user",
    "remove-trainer": "Remove trainer",
    "remove-from-company": "Remove from company",
    "remove-from-certification-authority": "Remove from certification authority",
    "create-user": "Create user",
    "the-most-important-information": "The most important information",
    "create-company": "Create company",
    "the-most-important-information-about-the-company": "The most important information about the company",
    "create-company-vehicle": "Create company vehicle",
    "the-most-important-information-about-the-vehicle": "The most important information about the vehicle",
    "create-report": "Create report",
    "where-is-the-company-located": "Where is the company located",
    "where-is-the-certification-authority-located": "Where is the certification authority located",
    "whats-the-role-of-the-person": "Whats the role of the person",
    "where-does-the-person-live": "Where does the person live",
    "further-details": "Further Details",
    "register-successfull": "Register successfull",
    "great-you-will-receive-an-email": "Great you will receive an email with a password recovery link",
    "this-should-not-take-longer-than": "This should not take longer than 5 minutes. Please also look in your spam folder",
    "i-agree": "I agree",
    "to": "to",
    "terms-and-conditions": "terms and conditions",
    "hereby-i-agree": "I agree that Evalus GmbH can use my personal data for the purpose of issuing an Evalcard and for the user registration on my.evalcard.com",
    "further-i-agree-to": "Furthermore, I agree that Evalus GmbH can inform me via e-mail newsletter about news in the high-voltage area (new training courses, new products or events) until further notice.",
    "allowed-to-be-used": " (.de / .com)",
    "refusal-is-allways": "A revocation is anytime possible via email to ",
    "possible": "",
    "the-e-val-card-is-the": "The EVAL – Card is the proof of qualification in checkcard-format. The shortcut EVAL stands for Electric Vehicle Access License",
    "complete-user-data": "Complete user data",
    "complete-user-data-info": "For proper support and to make use of all features, you need to have a complete user-profile",
    "your-password-reset-is-being-processed": "Your password reset is being processed. If this takes longer than a few minutes, try again",
    "your-password-has-been-sent": "Your password has been recovered successfully. You should receive an email with your new password within a few minutes.",
    "if-it-did-not-work": "Please also look in your spam folder. If it takes longer than a few minutes, try again.",
    "user-not-authorized": "Username or Password wrong",
    "user-not-activated": "This user is not activated. Use the link in the registration email",
    "user-or-password-wrong": "Username or Password wrong",
    "users-selected": "Users selected",
    "user-profile-update-successfull": "User-profile update successfull",
    "user-profile-update-failed": "User-profile update failed. Please try again later ",
    "do-you-really-want-to-leave-the-company": "Do you really want to leave the company?",
    "in-order-to-accept-a-company-invitation": "To accept a company invitation, you need to be registered and logged in",
    "do-you-really-want-to-join-the-company": "Do you really want to join the company?",
    "if-you-are-already-in-a-company-you-will-be-removed": "If you are already in a company you will be removed",
    "you-are-not-a-member-of-a-company": "You are not in a company. As soon as you are in a company, you can use this page",
    "you-are-not-a-member-of-a-certification-authority": "You are not a member of a certification authority",
    "do-you-really-want-the-company-to": "Do you really want to delete the company",
    "company-created-successfully": "Company created successfully",
    "company-already-exists": "company with this Name/Email already exists",
    "vehicle-save-successfully": "Vehicle saved successfully",
    "vehicle-already-exists": "Vehicle with this VIN and owner already exists",
    "vehicle-accepted": "Vehicle got merged into the system",
    "vehicle-manufacturer-accepted": "Vehiclemanufacturer got merged into the system",
    "report-save-successfully": "Report saved successfully",
    "report-already-exists": "Report already exists",
    "create-company-failed": "Create company failed. Please try again later",
    "save-user-success": "User saved successfully",
    "user-already-exists": "A User with that E-mail or Name already exists",
    "save-company-success": "Company saved successfully",
    "save-company-failed": "Saving company failed. Try again later",
    "save-changes-success": "Saved changes successfully",
    "do-you-really-want-the-vehicle-to": "Do you really want to delete the vehicle",
    "do-you-really-want-the-report-to": "Do you really want to delete the report",
    "do-you-really-want-the-certificate-to": "Do you really want to delete the certificate",
    "do-you-really-want-the-vehicle-manufacturer-to": "Do you really want to delete the manufacturer",
    "save-vehicle-manufacturer-success": "Vehiclemanufacturer saved successfully",
    "save-vehicle-manufacturer-error": "Save vehiclemanufacturer failed. Try again later",
    "save-vehicle-success": "vehicle saved successfully",
    "save-vehicle-error": "Saveing vehicle failed",
    "who-works-in-the-company": "Who works in the company?",
    "who-works-in-the-certification-authority": "Who works in the certification authority?",
    "your-company-has-been-created-check-your-emails": "Your company has been created check your emails. Please also look in your spam folder",
    "if-you-havent-received-an-email-yet": "If you havent received an email yet. You can try to send it again here",
    "if-this-did-not-work": "If this did not work. You can try leaving the company and try again from scratch",
    "is-already-in-a-company": "is already in a company",
    "invite-user-already-exists-in-company": "User with that email already exists in company",
    "invite-user-already-exists-in-other-company": "User is already in another company",
    "create-certification-authority": "Create certification-authority",
    "certification-authority-save-successfully": "Certification-authority saved successfully",
    "certification-authority-already-exists": "certification-authority with this Email/Name already exists",
    "certificate-already-exists": "Certificate with this name already exists",
    "certificate-save-successfully": "Certificate saved successfully",
    "do-you-really-want-the-certification-authority-to": "Do you really want to delete the certification authority",
    "create-certificate": "Create certificate",
    "create-certificate-template": "Create certificate-template",
    "who-gets-the-certificate": "Who receives the certificate",
    "user-with-this-eval-id-was-not-found": "User with this eval-id was not found",
    "you-can-only-add-reports-if-you-have-company-vehicles": "You can only add reports if you have company vehicles",
    "you-can-only-input-eu-numbers": "You can only input the eu number-format",
    "you-dont-have-the-rights": "You dont have the rights for this feature",
    "certificate-template-save-success": "Certificate-template saved successfully",
    "certificate-template-sort-save-success": "Certificate-template sorting saved successfully",
    "your-certification-authority-does-not-have-templates": "Create a template in your certification-authority first",
    "this-company-is-not-yet-activated": "This company is not yet activated. An email with the activation link should be sent",
    "welcome-to-the-dashboard": "Welcome to the dashboard! You are not yet in a company. You will see an overview of your reports here once you are in a company",
    "valid-certificates": "Valid certificates",
    "certificates-last-30-days": "Certificates - last 30 days",
    "reports-last-30-days": "Reports - last 30 days",
    "no-data-available": "No data available yet",
    "this-manufacturer-does-not-exist": "This manufacturer does not exist in the system yet. It will created for you",
    "this-vehicle-does-not-exist": "This vehicle does not exist yet. It will be created for you",
    "required-to-complete-registration": "To complete the registration this is required",
    "username-is-already-used": "This username is already used",
    "email-is-already-used": "This email is already used",
    "enter-valid-birthday-date": "Please enter a valid birthday",
    "enter-valid-eu-number-format": "Please enter only numbers in the EU-Number format",
    "only-use-numbers-with-or": "Please only use numbers and '-' or '/'",
    "this-user-is-already-in-this-certification-authority": "This user is already in this certification authority",
    "this-user-is-already-selected": "This user is already selected",
    "this-user-is-already-in-a-company": "This user is already in a company",
    "the-document-could-not-be-loaded": "The document could not be loaded",
    "": "",
    "continue": "Continue",
    "finish": "Finish"
  },
  "tooltip": {
    "csv-export": "CSV Export",
    "add-user": "Add user",
    "invite-user": "Invite user",
    "delete-user": "Delete user",
    "remove-user": "Remove user",
    "edit-user": "Edit user",
    "view-user": "View user",
    "send-invitation-again": "Send invitation again",
    "send-activation-again": "Send activation-link again",
    "admin-cant-leave-company": "As eval-client you cant-leave the company",
    "leave-company": "Leave company",
    "add-company": "Add company",
    "edit-company": "Edit company",
    "delete-company": "Delete company",
    "add-selected-users-to-company": "Add selected users to company",
    "add-vehicle": "Add vehicle",
    "show-vehicle": "Vehicle details",
    "edit-vehicle": "Edit vehicle",
    "view-vehicle": "View vehicle",
    "accept-vehicle": "Accept vehicle",
    "accept-vehicle-manufacturer": "Accept vehicle manufacturer",
    "delete-vehicle": "Delete vehicle",
    "add-vehicle-manufacturer": "Add vehicle manufacturer",
    "edit-vehicle-manufacturer": "Edit vehicle manufacturer",
    "delete-vehicle-manufacturer": "Delete vehicle-manufacturer",
    "vehicle-details-missing": "Vehicle Details missing",
    "remove-invitation": "Remove invitation",
    "send-missing-activations": "Send missing activations",
    "create-report": "Create report",
    "select-for-report": "select for report",
    "add-question": "Add question",
    "remove-question": "Remove question",
    "create-certificates": "Create certificates",
    "view-certificate": "View certificate",
    "delete-certificate": "Delete certificate",
    "view-report": "View report",
    "delete-report": "Delete report",
    "no-pdf-found": "No pdf found",
    "add-report": "Add-report",
    "add-certificate": "Add certificate",
    "no-certificate-to-create-report": "You don't own a certificate to create reports",
    "add-certification-authority": "Add certification-authority",
    "edit-certification-authority": "Edit certification-authority",
    "delete-certification-authority": "Delete certification-authority",
    "add-certificate-template": "Add certificate-template",
    "edit-certificate-template": "Edit certificate-template",
    "delete-certificate-template": "Delete certificate-template",
    "view-certificate-training-data": "View certificate training document",
    "save-sorting": "Save sort",
    "mark-user": "Mark user for csv-export",
    "open-topic": "Open Topic",
    "edit-topic": "Edit Topic",
    "delete-topic": "Delete Topic",
    "edit-topic-answer": "Edit Answer",
    "delete-topic-answer": "Delete Answer",
    "select-all-users": "Select all current users for csv-export"
  },
  "phone": "Phone",
  "address": "Address",
  "birthday": "Birthday",
  "profile-picture": "Profile-picture",
  "userprofile": {
    "edit-profile": "Edit profile",
    "public-profile": "Public profile",
    "following-information": "Following information will be shown in the public profile"
  },
  "training-director": "Trainer",
  "participated-the-training": "participated the training ",
  "result-protocol": "Result protocol",
  "measurements": "Measurements",
  "freischaltung": "Disconnection",
  "isolationswiderstand": "Isolation resistance",
  "potentialausgleich": "Potential equalization",
  "r": {
    "measuring-instrument-functionality": "Measuring instrument functionality",
    "measuring-instrument-functionality-tested": "Measuring instrument functionality tested",
    "positive-test-done": "Positive-test done",
    "voltage-between-hv-plus-and-chassis": "Voltage between hv plus and chassis",
    "voltage-between-hv-plus-and-hv-minus": "Voltage between hv plus and hv minus",
    "voltage-between-hv-minus-and-chassis": "Voltage between hv minus and chassis",
    "the-tester-accepts-the-security-instructions": "The tester confirms compliance with the manufacturer's safety instructions",
    "measuring-instrument-tested-at-reference-source": "Measuring instrument tested at reference source",
    "measuring-instrument-tested": "Meter function checked?",
    "absence-of-voltage": "Absence of voltage",
    "absence-of-voltage-confirmed": "Absence of voltage confirmed",
    "voltage": "Voltage",
    "used-voltage": "Used voltage",
    "used-test-voltage": "Used test voltage",
    "isolation-resistance-between-hv-plus-and-chassis": "Isolation resistance between hv plus and chassis",
    "isolation-resistance-between-hv-minus-and-chassis": "Isolation resistance between hv minus and chassis",
    "target-isolation-resistance-value": "Target isolation resistance value",
    "isolation-standard": "Target isolation resistance",
    "resistance-per-volt": "Resistance per Volt",
    "hv-system-voltage": "HV-System voltage",
    "further-measurements-if-needed": "Further measurements if needed",
    "test-current": "Test current (A)",
    "test-current-q": "Test current...",
    "measuring-point": "Measuring point",
    "measuring-point-chassis-vs": "Measuring point chassis vs...",
    "converter-housing": "Converter housing",
    "further-voltage-measurements-if-needed": "Further voltage measurements if needed",
    "measurements-ok-if-smaller-than-1-v": "Measurements ok if smaller than 1V",
    "measuring-instrument": "Measuring instrument"
  },
  'new-report-no-id': 'New report',
  'required-field': 'This field is mandatory',
  'fill-out-all-required-fields': 'Please fill out all mandatory fields!',
  'valid-email-only': 'Please fill in a valid mail address.'
}
