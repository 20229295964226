// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import { createApp } from 'vue'
import i18n from './i18n'
import './plugins/globalFunctions'
import vuetify from './plugins/vuetify'
import router from './router/index'
import store from './store/index'
import axios from './plugins/axios'
import VueAxios from 'vue-axios'
import VuetifyUseDialog from 'vuetify-use-dialog'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'

const app = createApp(App)

if (!window.location.href.includes('localhost')) {
  Sentry.init({
    app,
    environment: process.env.NODE_ENV,
    dsn: 'https://b9672cba24d24486bda6f2d2f272682d@sentry.seadev-studios.com/2',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'myeval.at'],
      }),
    ],
    tracesSampleRate: 0.2,
  })
}



app.use(VueAxios, axios)
app.use(VuetifyUseDialog)
app
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(ToastPlugin)
  .mount('#app')
