import { createI18n } from 'vue-i18n'

import { en } from '@/locales/en'
import { de } from '@/locales/de'
import { it } from '@/locales/it'
import axios from 'axios'

const messages = {
  en,
  de,
  it,
}

const instance = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages,
  globalInjection: true,
  allowComposition: true,
})

export function setLocale(lang: string) {
  if (lang !== 'de' && lang !== 'en' && lang !== 'it') return
  instance.global.locale.value = lang

  axios.defaults.headers.common['Accept-Language'] = getLocale()
}

export function getLocale() {
  return instance.global.locale.value
}

export default instance
